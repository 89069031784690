import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { getIsUserAuthenticated } from "../services/auth";
import { loginRequest } from "../msal/authConfig";

import "../assets/css/main.scss";

const Training = () => {
  const { instance } = useMsal();

  const isAuthenticated = getIsUserAuthenticated();

  // const guidelines = data?.guidelines?.nodes || [];
  // console.log('guidelines', guidelines);

  useEffect(() => {
    if (isAuthenticated) {
      location.href = 'https://training.florabank.org.au/auth/azureb2c/';
    }
  }, [])

  return (
    <>
    {
      isAuthenticated ? <></> :
      <Layout>
        <Container className="training">
          <div>
            <p>
              The Florabank Training system provides a self-paced, online training course for the Florabank Guidelines. 
              The course covers all Guidelines and is arranged into four modules:
            </p>

            <ol className="my-2">
              <li>Collection - Introduction (Guideline 1), Seed Sourcing (Guideline 2) and Seed Collection (Guideline 5).</li>
              <li>Cleaning - Seed Cleaning (Guideline 5) and Seed Drying and Storage (Guideline 6).</li>
              <li>Storage - Seed Drying and Storage (Guideline 6), Seed Quality (Guideline 7) and Seed Germination Testing (Guideline 8).</li>
              <li>Production - Seed Production Areas (Guideline 4), Seed Enhancement Technologies (Guideline 9), Nursery Propagation (Guideline 10) and Direct Seeding (Guideline 11).</li>
            </ol>
            <p>The remaining Guidelines are covered in multiple modules.</p>
            <p>Badges indicating successful completion of each module may be displayed on your user profile</p>
            <Button className="btn btn-success" target="_blank" href="https://training.florabank.org.au/login/index.php">Sign into Moodle</Button>
          </div>
        </Container>
      </Layout>
    }
    </>
  );
};


// export const query = graphql`
//   query Guidelines2 {
//     guidelines: allStrapiGuideline {
//       nodes {
//         id
//         blurb
//         title
//         link
//         sortOrder
//         contents
//       }
//     }
//   }
// `;

export default Training;